const initState = {
  hover: false,
  cursor: "default",
};

export const setHover = payload => ({
  type: 'SET_HOVER',
  payload,
});

export const setCursor = payload => ({
  type: 'SET_CURSOR',
  payload,
});

const cursor = (state = initState, action) => {
  switch (action.type) {
    case 'SET_HOVER':
      const hover = action.payload;
      return { ...state, hover }
    case 'SET_CURSOR':
      const cursor = action.payload;
      return { ...state, cursor }
    default:
      return state
  }
}

export default cursor