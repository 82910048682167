import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from "react-router-dom";
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducers from './reducers'
import history from "./history";

let store = createStore(reducers, applyMiddleware(thunk, promise));

history.listen((location) => {
  const page = location.pathname + location.search;
  if (window.gtag)
    window.gtag('config', 'UA-157456487-2', {
      'page_title': document.title,
      'page_path': page
    });
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Suspense fallback={<div></div>}>

      <Route render={({ location }) => (
        <App history={history} location={location} />
        )} />
      </Suspense>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
