import React, { lazy } from 'react';
import { Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { CookieBanner } from '@palmabit/react-cookie-law';

import './styles/global.scss';
import { wait } from './utils/global';

const MainMenu = lazy(() => import('./components/MainMenu'));
const Intro = lazy(() => import('./components/Intro'));
const Home = lazy(() => import('./containers/Home'));
const Work = lazy(() => import('./containers/Work'));
const About = lazy(() => import('./containers/About'));
const Contact = lazy(() => import('./containers/Contact'));
const Team = lazy(() => import('./containers/Team'));

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loaded: false,
      introFinished: false,
      onIntroAnimateOut: false,
      videoBreakpoints: [ 0, 52, 100, 150, 200 ],
      moreInfoOpen: false,
      activePosition: 0,
      minPosition: 0,
      maxPosition: 4,
      openMenu: false,
      coverShow: true,
      screenTransition: false,
      screenTransitionRun: false
    }
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  onIntroFinish() {
    this.setState({ introFinished: true });
  }

  onIntroAnimateOut() {
    this.setState({ onIntroAnimateOut: true });
  }

  onToggleMenu(open_menu) {
    this.setState({ openMenu: open_menu })
  }

  onCoverToggle(coverShow) {
    this.setState({ coverShow });
  }

  transitionToScreen(screen) {
    const { history } = this.props;

    this.setState({ screenTransition: true });
    wait(10, () => this.setState({ screenTransitionRun: true }));
    wait(400, () => {
      this.onCoverToggle(true);
      history.push(screen);
    });
    wait(1500, () => {
      this.setState({ screenTransition: false, screenTransitionRun: false })
    });
  }

  render() {
    const { loaded, openMenu, onIntroAnimateOut, coverShow, screenTransition, screenTransitionRun } = this.state;
    const { location } = this.props;
    let webWrapperClass = coverShow ? "web-wrapper" : "web-wrapper topbar-alt"
    if (coverShow && screenTransition) {
      webWrapperClass = "web-wrapper no-transition";
    }
    const screenTransitionClass = screenTransitionRun ? "screens-transition run" : "screens-transition";
    const deleteGoogleCookies = () => {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          name = name.replace(' ', '');
          if (
            name === "1P_JAR" ||
            name === "CONSENT" ||
            name === "NID" ||
            name === "_ga" ||
            name === "_gat_gtag_UA_157456487_2" ||
            name === "_gid"
          ) {
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }
      }
    }

    return (
      <div className={webWrapperClass}>
        {screenTransition && (
        <div className={screenTransitionClass}>
          <div className="cover"></div>
        </div>
        )}
        <Intro
          onFinish={() => this.onIntroFinish()}
          onAnimateOut={() => this.onIntroAnimateOut()}
        />
        <MainMenu onToggleMenu={(e) => this.onToggleMenu(e)} ref="mainMenu" />
        {loaded && (
          <>
            {/* policyLink="www.google.com" */}
            <CookieBanner
              message={'We use our own and third-party cookies to obtain statistical data on the navigation of our users and improve our services. If you accept or continue browsing, we consider that you accept its use.'}
              showMarketingOption={false}
              showPreferencesOption={false}
              onAccept = {() => {}}
              onDeclineStatistics = {() => {
                window['ga-disable-UA_157456487_2'] = true;
                deleteGoogleCookies();
              }}
              styles={{
                dialog: {
                  position: 'fixed',
                  top: 'initial',
                  bottom: '0rem',
                  left: '0rem',
                  right: '0rem',
                  backgroundColor: 'rgb(0, 0, 0)',
                  padding: '6rem 4rem',
                  zIndex: 9999
                },
                message: {
                  color: '#ffffff',
                  padding: '10px 0px',
                  paddingTop: 0,
                  minHeight: '32px',
                  fontSize: '10pt',
                  fontWeight: 400,
                  lineHeight: '130%',
                  maxWidth: '815px'
                },
                policy: {
                  fontSize: '10pt',
                  textDecoration: 'underline',
                  color: '#ffffff',
                  marginLeft: '0px',
                  display: 'none',
                },
                selectPane: {
                  float: 'left'
                },
                optionWrapper: {
                  display: 'inline-block',
                  position: 'relative',
                  marginRight: '3rem',
                  marginLeft: 0 ,
                  float: 'left'
                },
                optionLabel: {
                  height: 'auto',
                  width: 'auto',
                  minHeight: '14px',
                  fontSize: '12pt',
                  color: 'rgb(198, 198, 198)',
                  display: 'inline-block',
                  padding: '5px 0px 0px 25px',
                  position: 'relative',
                  top: '0px',
                  left: '0px',
                  zIndex: 1,
                  cursor: 'default',
                  verticalAlign: 'top'
                }
              }}
            />
            <TransitionGroup className="screens">
              <CSSTransition
                key={location.key}
                classNames="screen"
                timeout={0}
              >
                <Switch location={location}>
                  <Route exact path="/">
                    <Home
                      ref="home"
                      openMenu={openMenu}
                      onIntroAnimateOut={onIntroAnimateOut}
                      transitionToScreen={screen => this.transitionToScreen(screen)}
                    />
                  </Route>
                  <Route path="/work">
                    <Work
                      openMenu={openMenu}
                      onIntroAnimateOut={onIntroAnimateOut}
                      onCoverToggle={coverShow => this.onCoverToggle(coverShow)}
                      transitionToScreen={screen => this.transitionToScreen(screen)}
                    />
                  </Route>
                  <Route path="/about">
                    <About
                      openMenu={openMenu}
                      onIntroAnimateOut={onIntroAnimateOut}
                      onCoverToggle={coverShow => this.onCoverToggle(coverShow)}
                      transitionToScreen={screen => this.transitionToScreen(screen)}
                    />
                  </Route>
                  <Route path="/team">
                    <Team
                      onCoverToggle={coverShow => this.onCoverToggle(coverShow)}
                      transitionToScreen={screen => this.transitionToScreen(screen)}
                    />
                  </Route>
                  <Route path="/contact">
                    <Contact
                      onCoverToggle={coverShow => this.onCoverToggle(coverShow)}
                      transitionToScreen={screen => this.transitionToScreen(screen)}
                    />
                  </Route>
                  <Route>
                    <Home
                      ref="home"
                      openMenu={openMenu}
                      onIntroAnimateOut={onIntroAnimateOut}
                      transitionToScreen={screen => this.transitionToScreen(screen)}
                    />
                  </Route>
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </>
        )}
      </div>
    );
  }
}

export default App;
