export function animate(targets_class) {
  const targets = document.getElementsByClassName(targets_class);
  const activeClass = 'show';

  for (let target of targets) {
    const callback = function(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(activeClass);
        } else {
          if (entry.boundingClientRect.top > 0) {
            entry.target.classList.remove(activeClass);
          }
        }
      });
    };

    const observer = new IntersectionObserver(callback, { threshold: 0, rootMargin: "0px 0px -60px 0px" });
    observer.observe(target);
  };
}

export function wait(time, callback) {
  setTimeout(() => {
    if (callback)
      callback();
  }, time);
}

export function getProp(func) {
  return func ? func : () => {};
}

export function scrollTo(element, to = 0, duration= 1000) {

  const start = element.scrollTop;
  const change = to - start;
  const increment = 20;
  let currentTime = 0;

  const animateScroll = (() => {

    currentTime += increment;

    const val = Math.easeInOutQuad(currentTime, start, change, duration);

    element.scrollTop = val;

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  });

  animateScroll();
};

Math.easeInOutQuad = function (t, b, c, d) {

  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};